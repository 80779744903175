import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// @ts-ignore
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ReactGA from "react-ga4";
// hooks
import { useActions } from "../../common/hooks/useActions";
import { getCurrentDeviceBasedOnViewport } from "../../common/utils/helpers/currentDeviceHelpers/getCurrentDeviceBasedOnViewport";
// actions
import { exploreActionCreators } from "./exploreModule";
// components
import ExploreSidebar from "./ExploreSidebar";
import AppOfferCard from "../../components/ui/AppOfferCard";
import AppButton from "../../components/ui/AppButton";
import AppProviderBox from "../../components/ui/AppProviderBox";
import AppSlider from "../../components/AppSlider";

export default function Explore() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const keywordParamDefault: string | null = searchParams.get("keyword");
  const categoryParamDefault: string | null = searchParams.get("categories");

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [device, setDevice] = useState("");

  const language = localStorage.getItem("i18nextLng");

  // states
  const otherOffers = useSelector((state: any) => state.explore.otherOffers);
  const promotedOffers = useSelector((state: any) => state.explore.promotedOffers);
  const transportOptions = useSelector((state: any) => state.explore.transportOptions);
  const hotelInfo = useSelector((state: any) => state.hotel.hotelInfo);
  const defaultDomain = useSelector((state: any) => state.global.defaultDomain);
  const totalResultsOtherOffers: number = useSelector((state: any) => state.explore.totalResultsOtherOffers);
  const totalResultsPromotedOffers: number = useSelector((state: any) => state.explore.totalResultsPromotedOffers);
  const globalLoader = useSelector((state: any) => state.global.globalLoader);

  // actions
  const getOffers = useActions(exploreActionCreators?.getOffersAction, []);
  const getOtherOffers = useActions(exploreActionCreators?.getOtherOffersAction, []);
  const getPromotedOffers = useActions(exploreActionCreators?.getPromotedOffersAction, []);
  const resetFilterOffers = useActions(exploreActionCreators?.resetFilterOffersAction, []);
  const getTransportOptions = useActions(exploreActionCreators?.getTransportOptionsAction, []);

  useEffect(() => {
    // Attach the handleResize function to the window resize event
    window.addEventListener("resize", handleResize);
    // Call it initially to set up your responsive design
    handleResize();
  }, []);

  useEffect(() => {
    // to be sure that we have already called hotel-info and config.json, before calling getOffers actions
    if (hotelInfo && defaultDomain && otherOffers === null) {
      getOffers(
        {
          offset: 1,
          keyword: keywordParamDefault,
          categories: categoryParamDefault
            ? [categoryParamDefault]?.[0].split(",").map(category => category.trim())
            : []
        },
        device
      );
      getTransportOptions();
    }
  }, [hotelInfo, defaultDomain]);

  useEffect(() => {
    // Perform responsive actions based on viewportWidth and corresponding device if the device is not already detected
    if (hotelInfo && defaultDomain && otherOffers !== null && otherOffers?.length !== 0) {
      getOffers(
        {
          offset: 1,
          keyword: keywordParamDefault,
          categories: categoryParamDefault
            ? [categoryParamDefault]?.[0].split(",").map(category => category.trim())
            : []
        },
        device
      );
    }
  }, [device]);
  //

  // handle responsive changes
  useEffect(() => {
    setDevice(getCurrentDeviceBasedOnViewport(viewportWidth));
  }, [viewportWidth]);

  // handle removing categories from URL if is empty
  useEffect(() => {
    if (!categoryParamDefault && hotelInfo && defaultDomain) {
      if (keywordParamDefault) {
        navigate(`/${language}?keyword=${keywordParamDefault}`, { replace: true });
      } else {
        navigate(`/${language}`, { replace: true });
      }
    }
  }, [categoryParamDefault]);

  // translation purposes
  useEffect(() => {
    if (hotelInfo && defaultDomain && otherOffers !== null && otherOffers?.length !== 0) {
      getOffers(
        {
          offset: 1,
          keyword: keywordParamDefault,
          categories: categoryParamDefault
            ? [categoryParamDefault]?.[0].split(",").map(category => category.trim())
            : []
        },
        device
      );
      getTransportOptions();
    }

    // if keywordParamDefault is set, include it in URL
    if (keywordParamDefault) {
      if (categoryParamDefault) {
        navigate(
          `/${language}?keyword=${keywordParamDefault}&categories=${encodeURIComponent(categoryParamDefault)} `,
          {
            replace: true
          }
        );
      } else {
        navigate(`/${language}?keyword=${keywordParamDefault}`, { replace: true });
      }
    } else {
      if (categoryParamDefault) {
        navigate(`/${language}?categories=${encodeURIComponent(categoryParamDefault)} `, { replace: true });
      } else {
        navigate(`/${language}`, { replace: true });
      }
    }
  }, [language]);

  const handleResize = () => {
    setViewportWidth(window.innerWidth);
  };

  const onCardClick = (code: number, name: string, isPromo?: boolean) => {
    const actionName = isPromo ? "promo-offer-selected" : "offer-selected";
    ReactGA.event({
      category: "Offers List",
      action: `${hotelInfo.code}-${actionName}`,
      label: `list - ${name}`
    });

    navigate(`/${language}/offers/${code}`, {
      state: {
        offer_code: code,
        name: name
      }
    });
  };

  const onTransportCardClick = () => {
    // GA4 - track click event on the SBB button
    ReactGA.event({
      category: "Public Transport Tickets",
      action: "sbb-card-click",
      label: "sbb"
    });
    navigate(`/${language}/transport/sbb`);
  };

  const onLoadMoreOtherOffersButton = () => {
    getOtherOffers(
      {
        concat: true,
        keyword: keywordParamDefault,
        categories: categoryParamDefault ? [categoryParamDefault]?.[0].split(",").map(category => category.trim()) : []
      },
      device
    );
  };

  const onLoadMorePromotedOffersButton = () => {
    getPromotedOffers(
      {
        concat: true,
        keyword: keywordParamDefault,
        categories: categoryParamDefault ? [categoryParamDefault]?.[0].split(",").map(category => category.trim()) : []
      },
      device
    );
  };

  const resetFilters = () => {
    setSearchParams({}); // Remove keyword param from URL
    resetFilterOffers();
  };

  return (
    <Grid className="grid-box explore" container mt={1} mb={5} columnSpacing={{ xs: 0, sm: 0, md: 5 }}>
      <Grid item xs={12} md={5} lg={4}>
        <ExploreSidebar
          keywordParamDefault={keywordParamDefault}
          categoryParamDefault={categoryParamDefault}
          resetFilters={resetFilters}
          device={device}
        />
      </Grid>

      <Grid item xs={12} md={7}>
        <Grid container spacing={{ xs: 1, sm: 2 }} mb={3}>
          {transportOptions?.groups?.map((group: any, index: number) => {
            return (
              <AppProviderBox
                key={index}
                title={group?.name}
                providerCover={group?.provider_cover}
                onClick={onTransportCardClick}
              />
            );
          })}
        </Grid>
        {globalLoader || otherOffers?.length > 0 || promotedOffers?.length > 0 || keywordParamDefault === null ? (
          <>
            {promotedOffers?.length > 0 && (
              <>
                <Box className="box-header">{t("explore.titleForPromotedBox")}</Box>
                <Grid container spacing={{ xs: 4, sm: 4 }} mb={4}>
                  {promotedOffers?.map((product: any) => {
                    return (
                      <AppOfferCard
                        key={product.offer_code}
                        name={product.name}
                        description={product.short_description}
                        id={product.offer_code}
                        image={product?.image_preview}
                        price={product.price}
                        priceLabel={product?.price?.label}
                        onClick={onCardClick}
                        customColor={hotelInfo?.style?.page?.box_color}
                        isPromo={true}
                      />
                    );
                  })}
                </Grid>
              </>
            )}
            {totalResultsPromotedOffers !== promotedOffers?.length && promotedOffers !== null && (
              <Grid mt={4} mb={4}>
                <AppButton
                  label={t("explore.loadPromotedButton")}
                  onClick={onLoadMorePromotedOffersButton}
                  disableRipple={true}
                  customBackgroundColor={hotelInfo?.style?.button?.color}
                  customActiveColor={hotelInfo?.style?.button?.active}
                  customHoverColor={hotelInfo?.style?.button?.hover}
                  customClickColor={hotelInfo?.style?.button?.click}
                  fontSize="14px"
                  dataAttribute="load-more-promoted-offers"
                />
              </Grid>
            )}
            {otherOffers?.length > 0 && (
              <>
                <Box className="box-header">{t("explore.titleForOtherOffersBox")}</Box>
                <Grid container spacing={{ xs: 4, sm: 4 }}>
                  {otherOffers?.map((product: any) => {
                    return (
                      <AppOfferCard
                        key={product.offer_code}
                        name={product.name}
                        description={product.short_description}
                        id={product.offer_code}
                        image={product?.image_preview}
                        price={product.price}
                        priceLabel={product?.price?.label}
                        onClick={onCardClick}
                        customColor={hotelInfo?.style?.page?.box_color}
                      />
                    );
                  })}
                </Grid>
              </>
            )}
            {totalResultsOtherOffers !== otherOffers?.length && otherOffers !== null ? (
              <Grid mt={4}>
                <AppButton
                  label={t("explore.loadMoreButton")}
                  onClick={onLoadMoreOtherOffersButton}
                  disableRipple={true}
                  customBackgroundColor={hotelInfo?.style?.button?.color}
                  customActiveColor={hotelInfo?.style?.button?.active}
                  customHoverColor={hotelInfo?.style?.button?.hover}
                  customClickColor={hotelInfo?.style?.button?.click}
                  fontSize="14px"
                  dataAttribute="load-more-offers"
                />
              </Grid>
            ) : null}
          </>
        ) : (
          <Box data-cy="no-offers-box">
            <Box>{t("explore.noOffersMessageHeader", { keywordParamDefault: keywordParamDefault })}</Box>
            <Box mt={3} className="no-offers-message" data-cy="no-offers-message">
              {t("explore.noOffersMessage")}
            </Box>
            <Box mt={4} mb={2} width="141px">
              <AppButton
                label={t("explore.search.clearSearchButton")}
                onClick={resetFilters}
                variant="contained"
                disableRipple={true}
                customBackgroundColor="#CDCDCD"
                labelColor="#3C3C3C"
                fontSize="14px"
                dataAttribute="no-offers-clear-button"
              />
            </Box>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
